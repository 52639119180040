import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bolao',
  templateUrl: './bolao.component.html',
  styleUrls: ['./bolao.component.scss'],

})
export class BolaoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
