<nav class="navbar navbar-style-two navbar-expand-lg navbar-light sticky-top">
    <div class="container-fluid">
        <div class="logo">
            <a routerLink="/home-nine">
                <img src="assets/img/logo.png" id="header-logo" class="black-logo" alt="Logo">
            </a>
        </div>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav">

                <li class="nav-item">
                    <a href="/download" class="nav-link">
                        Download App
                    </a>
                </li>
            </ul>

            <div class="others-option">
                <div class="d-flex align-items-center">
                    <div class="option-item">
                        <a href="https://forms.gle/MVamXvFK55eeJKvp6" class="sign-up">Become a Sponsor</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</nav>

<div id="home" class="better-home-area">
    <div class="container-fluid">
        <ul class="social-links">
            <li><a href="https://www.linkedin.com/company/nagringaapp"><i class='bx bxl-linkedin'></i></a></li>
            <li><a href="https://www.instagram.com/nagringa.app"><i class='bx bxl-instagram'></i></a></li>
        </ul>

        <div class="row align-items-top body-content" style="background-image: url(assets/images/site-banner.png); background-size: contain; background-position: bottom right; background-repeat: no-repeat; height: calc(100vh - 100px);">
            <div class="col-lg-6 col-md-12">
                <div class="better-home-content">
                    <h2 class="sub-title">28th Nov and 1st Dec <br/>CommBank Matildas x Brazil</h2>
                    <h1>BOLÃO NaGringa!</h1>
                    <p>Your chance to WIN PRIZES, and have fun with your friends. <Br/><span>It's FREE to JOIN!</span></p>
                    <a class="bolao" href="/download">
                      DOWNLOAD THE APP HERE
                    </a>
                    <div class="block">
                      <a class="bolao-a" href="/bolao">
                        Become a Sponsor
                      </a>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>

