<header>
  <img src="https://res.cloudinary.com/dwko3iqjs/image/upload/v1728480285/logo-full-white_256.png" height="40px">
</header>
<a class="banner matildas" href="https://events.humanitix.com/esquenta-nagringa?c=link" target="_blank">

</a>
<a class="matildas" href="https://events.humanitix.com/esquenta-nagringa?c=link" target="_blank">
  Grabe your Free Ticket here
</a>
<a class="banner bolao" [href]="url" target="_blank">

</a>

<a class="bolao-link" [href]="url" target="_blank">
  <div class="flex-1">
    Guess the Score of the game
    <br/>
    for a chance to win a Prizes
  </div>
  <span class="material-symbols-outlined">
    cloud_download
  </span>
</a>
