import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.scss']
})
export class LinksComponent {
  public url: String | null = null;

  ngOnInit(): void {

    var isiOS = /iPad|iPhone|iPod/i.test(navigator.userAgent);

    var isAndroid = /Android/i.test(navigator.userAgent);

    if (isiOS) {
      this.url = 'https://apps.apple.com/au/app/nagringa/id1608557362';
    } else if (isAndroid) {
      this.url = 'https://play.google.com/store/apps/details?id=app.nagringa.app';
    } else {
      this.url = 'https://promo.nagringa.app/download';
    }



  }
}
