import { Component, inject, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { AnalyticsService } from './services/analytics.service';
declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit {
    location: any;
    routerSubscription: any;

    private _analytics = inject(AnalyticsService)

    constructor(private router: Router) {
      this._analytics.track('nagringa.app')
      this._analytics.nav(this.router.url)
    }


    ngOnInit(){
        this.init();
    }

    init() {

        this.routerSubscription = this.router.events.pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
          .subscribe(event => {

            this.location = this.router.url;
              if (!(event instanceof NavigationEnd)) {
                  return;
              }
              console.log('> LOCATION');
              console.log(this.location);
              this._analytics.nav(this.location);
              window.scrollTo(0, 0);
          }
        );
    }
}
