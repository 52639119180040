import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent implements OnInit {


  public url: String | null = null;
  public qrUrl: String | null = null;

  @ViewChild('flag', { static: true})
  public flag: ElementRef | undefined;

  constructor(private router: Router) {

    this.qrUrl = `https://${location.host}${this.router.url}?utm_source=site-qr`;
    console.log(this.qrUrl);
  }

  ngOnInit(): void {

    var isiOS = /iPad|iPhone|iPod|Safari|Macintosh/i.test(navigator.userAgent);

    var isAndroid = /Android/i.test(navigator.userAgent);

    if (isiOS) {
      this.url = 'https://apps.apple.com/au/app/nagringa/id1608557362';
    } else if (isAndroid) {
      this.url = 'https://play.google.com/store/apps/details?id=app.nagringa.app';
    } else {
      // this.url = 'https://promo.nagringa.app/download';
    }

    if (this.url)
        (<any> window).location.href = this.url;


  }

}
