import { Injectable } from '@angular/core';
import mixpanel from 'mixpanel-browser';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor() {
    console.log('AnalyticsService');
    mixpanel.init(environment.mixpanelToken, {
      debug: !environment.production,
      track_pageview: true,
      persistence: "localStorage",
    });
  }

  public track(eventName: string, data?: any) {
    mixpanel.track(eventName, data);
  }

  public nav(page: string) {
    const pages = page.split('?');
    const url = pages[0];
    let data = {};
    if (pages.length > 1) {
      const params = pages[1].split('&');
      params.forEach((param) => {
        const keyVal = param.split('=');
        data[keyVal[0]] = keyVal[1];
      });
    }
    mixpanel.track(`nagringa.app${url}`, data);
  }
}
