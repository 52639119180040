import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit } from '@angular/core';

@Component({
  selector: 'app-sponsor',
  standalone: true,
  templateUrl: './sponsor.component.html',
  styleUrls: ['./sponsor.component.scss'],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class SponsorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
