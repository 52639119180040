<nav class="navbar navbar-style-two navbar-expand-lg navbar-light sticky-top">
    <div class="container-fluid">
        <div class="logo">
            <a routerLink="/home-nine">
                <img src="assets/img/logo.png" id="header-logo" class="black-logo" alt="Logo">
            </a>
        </div>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav">

                <li class="nav-item">
                    <a href="/download" class="nav-link">
                        Download App
                    </a>
                </li>
            </ul>

            <div class="others-option">
                <div class="d-flex align-items-center">
                    <div class="option-item">
                        <a href="https://forms.gle/MVamXvFK55eeJKvp6" class="sign-up">Become a Sponsor</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</nav>

<div id="home" class="better-home-area">
    <div class="container-fluid">

        <div class="row align-items-top body-content">
            <div class="col-lg-12 col-md-12">
                <div>
                    <h2 class="sub-title">Boost Your Business Visibility with</h2>
                    <h1>BOLÃO NaGringa!</h1>
                    <h2>45 days of engagement</h2>
                    <br/>
                    <br/>
                    <h2>Join this highly engaging promotion!</h2>
                    <p>
                      Bolão NaGringa is a digital competition that connects thousands of participants across Australia. With a core audience of Brazilians and International Communities, this free-to-enter competition allows people to predict game scores and win exciting prizes—offered by businesses just like yours!
                    </p>
                    <p>
                      By sponsoring Bolão NaGringa, you'll not only be aligning your brand with a fun and interactive event but also gaining direct access to a vibrant and growing international community. It's a <b>unique opportunity</b> to showcase your products or services, build brand loyalty, and reach potential customers in a digital and fun environment.
                    </p>
                    <br/>
                    <br/>

                    <h2>
                      Why Sponsor Bolão NaGringa?
                    </h2>
                    <ul>
                      <li>
                        <label>High Engagement</label>
                        Thousands of participants from across Australia who are eager to engage with the competition and the brands involved.
                      </li>
                      <li>
                        <label>Targeted Audience</label>
                        Our audience consists of Brazilian expats and International Communities — ideal if you're looking to expand your reach to culturally diverse and enthusiastic groups.
                      </li>
                      <li>
                        <label>Digital Exposure</label>
                        Your brand will be featured across our digital platforms, including social media, email campaigns, and in-app promotions, reaching a highly active online audience.
                      </li>
                      <li>
                        <label>Brand Recognition</label>
                        Offer a prize (or more!) from your business and let participants engage with your products/services. This drives word-of-mouth marketing and brings direct and organic traffic to your business.
                      </li>
                    </ul>

                    <br/>
                    <br/>
                    <h2>
                      How Does It Work?
                    </h2>
                    <ul>
                      <li>
                        <label>$100 Sponsorship Fee</label>
                        For just $100, your business can join the Bolão NaGringa promotion as an official sponsor.
                      </li>
                      <li>
                        <label>Give Away a Prize</label>
                        You'll have the opportunity to give away one or more prizes from your offerings, boosting your visibility and engagement with the participants.
                      </li>
                      <li>
                        <label>Ongoing Engagement</label>
                        Participants are encouraged to share, invite friends, and promote the competition—ensuring that your brand is seen far and wide.
                      </li>
                    </ul>
                    <br/>
                    <br/>
                    <h2>
                      Perfect for Businesses of All Sizes
                    </h2>
                    <p>
                      Whether you're a local service provider, a retailer, or an international brand, Bolão NaGringa offers an affordable way to promote your business to an engaged and diverse community.
                      Are you a small business or startup? Contact us on our instagram <a href="https://www.instagram.com/nagringa.app">&#64;nagringa.app</a> for a special discount on the sponsorship fee. We're here to help you grow and connect with new customers!
                    </p>

                </div>
            </div>

        </div>
    </div>
</div>
<footer class="container-fluid">
  <div class="backdrop"></div>
  <div class="btns">
    <div>
      <a class="bolao" href="/sponsor" analytics>
        BECOME A SPONSOR
      </a>
    </div>
    <div>
      <a class="bolao-a" href="/download">
        Download the App
      </a>
    </div>
  </div>

</footer>

