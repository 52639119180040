<nav class="navbar navbar-style-two navbar-expand-lg navbar-light sticky-top">
    <div class="container-fluid">
        <div class="logo">
            <a routerLink="/home-nine">
                <img src="assets/img/logo.png" id="header-logo" class="black-logo" alt="Logo">
            </a>
        </div>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav">

                <li class="nav-item">
                    <a href="/download" class="nav-link">
                        Download App
                    </a>
                </li>
            </ul>

            <div class="others-option">
                <div class="d-flex align-items-center">
                    <div class="option-item">
                        <a href="https://forms.gle/MVamXvFK55eeJKvp6" class="sign-up">Become a Sponsor</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</nav>
<stripe-pricing-table pricing-table-id="prctbl_1Q8fDLAlQG2JSP9YqKaSO8cm"
publishable-key="pk_live_51N1k6MAlQG2JSP9YPtY36fh00DXjqNEdGOcFpiMNh4Wg1tvZ8gwyHJSaXxvkkBQuM7QXcQzJvQmb3EluuqIVwITU00DmfRcIU1">
</stripe-pricing-table>
